import { createYupObjectSchema, genTestIds } from '@yieldstreet/tool-kit';
import { InvestorEntityStatus } from '@yieldstreet/platform-kit';
const {
  getTestIds
} = genTestIds(['loader', 'icon', 'status-success', 'status-pending', 'status-failed', 'status-invalid', 'status-action-needed', 'external-provider-info', 'tooltip', 'start-button', 'continue-button', 'contact-ir-button', 'legal-checkbox', 'error-notification']);
export const testIds = getTestIds('kyc');
export const getKYCValidationSchema = function () {
  let isRequired = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
  return createYupObjectSchema({
    legalAgreement: {
      validationType: 'default-boolean',
      isRequired
    }
  });
};
export const copy = {
  [InvestorEntityStatus.CONFIRMED]: {
    title: 'Identity successfully verified',
    cta: 'Continue',
    text: 'Since your identity has been verified, you are now ready to submit an investment request or continue with the account set-up process.'
  },
  [InvestorEntityStatus.PENDING]: {
    title: 'Identify verification in progress',
    cta: 'Contact investor relations',
    text: `We were unable to automatically verify your identity, and your information requires
    further review. Please allow up to 24 hours for completion. Please contact our
    Investor Relations team with any questions.`
  },
  [InvestorEntityStatus.INVALID]: {
    title: 'Account invalid',
    cta: 'Contact investor relations',
    text: `We encountered an issue while creating your account, rendering it invalid. Please contact us to resolve this issue.`
  },
  [InvestorEntityStatus.FAILED]: {
    title: 'We are not able to verify your identity',
    cta: 'Contact investor relations',
    text: `We were unable to verify your identity. Please contact us to resolve this issue.`
  },
  [InvestorEntityStatus.ACTION_NEEDED]: {
    title: 'We are not able to verify your identity',
    cta: 'Continue',
    text: `Our identity verification partner, requires additional info to complete their review.`,
    text_additional: `Please provide the requested info via their platform.`
  },
  Plaid: {
    title: 'Please verify your identity',
    cta: 'Continue',
    text: `We are required to verify your identity before you can invest.`,
    text_additional: `Identity verification (KYC) and secure data storage are provided by Plaid.`
  },
  Footprint: {
    title: 'Please verify your identity',
    cta: 'Continue',
    text: `We are required to verify your identity before you can invest.`,
    text_additional: `Identity verification (KYB/KYC) and secure data storage are provided by Footprint.`
  },
  tooltip: {
    title: 'Why do we ask for this information?',
    text: `The Patriot Act requires us to collect this information as part of the “Know Your
    Customer” process. This information is also needed for tax purposes. Your data is
    safe and protected. We follow security best practices and will never sell your
    information to a third party.`
  }
};