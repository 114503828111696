import { HttpMethods } from '../../enums/http';
import { Tags, restApi } from '../common';
export const termsAcceptanceApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.TermsAcceptance]
}).injectEndpoints({
  endpoints: builder => {
    const getTermsForAcceptance = builder.query({
      query: _ref => {
        let {
          userId,
          status
        } = _ref;
        return {
          url: `/a/api/terms-acceptance/${userId}/status/${status}`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.TermsAcceptance]
    });
    const getPublicTermsForAcceptance = builder.query({
      query: () => ({
        url: `/a/api/terms-acceptance/active`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.TermsAcceptance]
    });
    const saveAcceptedTerms = builder.mutation({
      query: _ref2 => {
        let {
          acceptanceId
        } = _ref2;
        return {
          url: `/a/api/terms-acceptance/user`,
          method: HttpMethods.Post,
          data: {
            acceptanceId
          }
        };
      },
      invalidatesTags: [Tags.TermsAcceptance]
    });
    return {
      getTermsForAcceptance,
      getPublicTermsForAcceptance,
      saveAcceptedTerms
    };
  }
});