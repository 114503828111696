import { skipToken } from '@reduxjs/toolkit/query';
import { allToken, termsAcceptanceApi, TermsStatus, userApi } from '@yieldstreet/platform-kit';
import { useMemo } from 'react';
import { generateConsentText } from './useTermsForAcceptance.helpers';
import isEmpty from "lodash/isEmpty";
export const useTermsForAcceptance = _ref => {
  let {
    enabled = false
  } = _ref;
  const {
    useGetUserStateQuery
  } = userApi;
  const {
    useGetTermsForAcceptanceQuery,
    useSaveAcceptedTermsMutation
  } = termsAcceptanceApi;
  const {
    data: userState,
    isLoading: userStateisLoading,
    isError: userStateIsError,
    error: userStateError,
    refetch: userStateRefetch
  } = useGetUserStateQuery(enabled ? allToken : skipToken);
  const getTermsParams = useMemo(() => {
    if (enabled && userState !== null && userState !== void 0 && userState.id) {
      return {
        status: TermsStatus.PENDING,
        userId: userState === null || userState === void 0 ? void 0 : userState.id
      };
    }
    return skipToken;
  }, [enabled, userState === null || userState === void 0 ? void 0 : userState.id]);
  const {
    data: {
      termsAcceptanceId: acceptanceId = '',
      termsDocuments: termsList = []
    } = {},
    isSuccess: termsIsSuccess,
    isLoading: termsIsLoading,
    isError: termsIsError,
    error: termsError,
    refetch: termsRefetch
  } = useGetTermsForAcceptanceQuery(getTermsParams);
  const [saveAcceptedTerms, {
    isSuccess: saveIsSuccess,
    isLoading: saveIsLoading,
    isError: saveIsError,
    error: saveError
  }] = useSaveAcceptedTermsMutation();
  let consentText = '';
  if (termsIsSuccess && (termsList === null || termsList === void 0 ? void 0 : termsList.length) > 0) {
    const docsList = termsList.map(doc => `[${doc.termsDocumentName}](${doc.url})`);
    consentText = generateConsentText(docsList);
  }
  const saveAcceptance = acceptanceId => {
    if (!isEmpty(acceptanceId)) {
      saveAcceptedTerms({
        acceptanceId
      });
    }
  };
  return {
    acceptanceId,
    consentText,
    saveAcceptance,
    skipTermsAcceptance: !isEmpty(acceptanceId) && isEmpty(termsList),
    isSuccess: termsIsSuccess,
    isLoading: termsIsLoading || userStateisLoading,
    isError: termsIsError || userStateIsError,
    error: termsError || userStateError,
    saveIsSuccess,
    saveIsLoading,
    saveIsError,
    saveError,
    refetch: () => {
      userStateRefetch();
      termsRefetch();
    }
  };
};